<template>
  <div class="mainform" >
    <div v-if="type==='1'?true:false">
      <div class="mainHeader">来料批不合格<span @click="back" class="back">返回></span></div>
      <div class="form">
        <div class="header" v-if="form.type == 1">基础信息</div>

        <el-form  v-if="form.type == 1" class="content bascform" :model="form" :class="{disabled:formDisabled}" :disabled="formDisabled"  label-width="120px">
          <div  class="col col4">
            <el-form-item label="通知单号" >
                <el-input v-model="form.qualityCode"></el-input>
            </el-form-item>
          </div>
          <div  class="col col4">
            <el-form-item label="公司名称" >
              <span class="onlyText">
                {{form.companyName}}
              </span>
            </el-form-item>
          </div>
          <div  class="col col4">
            <el-form-item label="单据类型" >
              <el-select v-model="form.type" >
                <el-option label="来料不合格" :value="1"></el-option>
                <el-option label="生产质量异常" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div  class="col col4">
            <el-form-item label="创建日期">
              <span class="onlyText">
                {{form.createDate}}
              </span>
            </el-form-item>
          </div>
          <!-- <div  class="col col4">
            <el-form-item label="备注" >
                <el-input v-model="form.bz"></el-input>
            </el-form-item>
          </div> -->
          <div  class="col col4">
            <el-form-item label="状态" >
                <el-select v-model="form.status" >
                  <el-option label="待采购确认" :value="1"></el-option>
                  <el-option label="待供应商确认" :value="2"></el-option>
                  <el-option label="完成" :value="3"></el-option>
                  <el-option label="已结案" :value="4"></el-option>
                </el-select>
            </el-form-item>
          </div>
        </el-form>

        <div class="header">质量对象</div>
        <el-form  class="content bascform" :model="form" :class="{disabled:formDisabled}" :disabled="formDisabled"  label-width="120px">
          <div  class="col col4">
            <el-form-item label="供应商编号" >
                <el-input v-model="form.supplierCode"></el-input>
            </el-form-item>
          </div>
          <div  class="col col4">
            <el-form-item label="供应商名称" >
              <span class="onlyText">
                {{form.supplierName}}
              </span>
            </el-form-item>
          </div>
          <div  class="col col4">
            <el-form-item label="物料编号" >
              <span class="onlyText">
                {{form.materielCode}}
              </span>
            </el-form-item>
          </div>
          <div  class="col col4">
            <el-form-item label="物料描述">
              <span class="onlyText">
                {{form.materielName}}
              </span>
            </el-form-item>
          </div>
          <div  class="col col4" v-if="form.type == 1">
            <el-form-item label="工厂" >
                <span class="onlyText">
                {{form.plantName}}
              </span>
            </el-form-item>
          </div>
          <div  class="col col4" v-if="form.type == 1">
            <el-form-item label="物料品类" >
              <el-input v-model="form.materielType"></el-input>
            </el-form-item>
          </div>
        </el-form>

        <div class="header"  v-if="form.type == 1">关联凭证</div>
        <el-form   v-if="form.type == 1" class="content bascform"  :model="associatedDocuments" :class="{disabled:formDisabled}" :disabled="formDisabled"  label-width="120px">
          <div  class="col col4">
            <el-form-item label="采购单编号" >
                <el-input v-model="associatedDocuments.orderCode"></el-input>
            </el-form-item>
          </div>
          <div  class="col col4">
            <el-form-item label="采购单行号" >
              <span class="onlyText">
                {{associatedDocuments.orderRow}}
              </span>
            </el-form-item>
          </div>
          <div  class="col col4">
            <el-form-item label="检验批" >
              <span class="onlyText">
                {{associatedDocuments.inspectionLot}}
              </span>
            </el-form-item>
          </div>
          <div  class="col col4">
            <el-form-item label="物料凭证年度">
              <span class="onlyText">
                {{associatedDocuments.materialYearDocument}}
              </span>
            </el-form-item>
          </div>
          <div  class="col col4">
            <el-form-item label="物料凭证号" >
                <span class="onlyText">
                {{associatedDocuments.materialVoucherNumber}}
              </span>
            </el-form-item>
          </div>
          <div  class="col col4">
            <el-form-item label="物料凭证行号" >
              <el-input v-model="associatedDocuments.materialVoucherRowNumber"></el-input>
            </el-form-item>
          </div>
          <div  class="col col4">
            <el-form-item label="数量" >
              <el-input v-model="form.defectsList[0].badNumber"></el-input>
            </el-form-item>
          </div>
          <div  class="col col4">
            <el-form-item label="备注说明" >
              <el-input v-model="form.bz"></el-input>
            </el-form-item>
          </div>
        </el-form>
        <global-table v-if="form.type == 1" class="bascform"  ref="defectsList" max-height="350" :tableField="tableFieldll" :tableData="defectsList" ></global-table>
        <div class="header" v-if="form.type == 2">异常描述</div>
        <global-table v-if="form.type == 2" class="bascform"  max-height="350" :tableField="tableFieldsc" :tableData="tableData" ></global-table>
        <div class="action" v-if="form.status == 1">
          <el-button  size="medium" type="primary" @click="showClosureDialog">结案</el-button>
          <el-button  size="medium" type="primary" @click="querySub">确认并通知供应商</el-button>
        </div>
      </div>
    </div>
    <div  v-if="type==='2'?true:false">
      <div class="mainHeader">生产质量异常通知单<span @click="back" class="back">返回></span></div>
      <div class="form">
        <div class="header">基础信息</div>
        <el-form class="content bascform" label-width="120px">
          <div class="col col4">
            <el-form-item label="通知单号：">
              <span>{{form.qualityCode}}</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="状态：">
              <span>{{form.status==1?'待采购确认':form.status==2?'待供应商确认':form.status==3?'完成':form.status==4?'已结案':form.status}}</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="单据类型:" >
              <span>生产质量异常</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="创建时间：">
                <span>{{form.createDate}}</span>
            </el-form-item>
          </div>
        </el-form>
        <div class="header">质量对象</div>
        <el-form class="content bascform" label-width="140px">
          <div class="col col4">
            <el-form-item label="维修物料编号：">
                <span>{{form.matnr}}</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="物料描述：">
              <span>{{form.maktx}}</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="订单发料数量：">
              <span>{{form.menge}}</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="不良数量：">
              <span>{{form.blqty}}</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="周结束日期：">
              <span>{{form.zedat}}</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="周不良率：">
              <span>{{form.blrate}}</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="供应商名称：">
              <el-select v-model="form.supplierName" filterable @change="supplierNameChange" :disabled="[3, 4].includes(form.status)" v-if="form.status == 1">
                <el-option v-for="item in basicData" :key="item.id" :label="item.name" :value="item.name"></el-option>
              </el-select>
              <span v-else>{{ form.supplierName }}</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="供应商编号：">
              <el-select v-model="form.supplierCode" filterable @change="erpCodeChange" v-if="form.status == 1">
                <el-option v-for="item in basicData" :key="item.id" :label="item.erpCode" :value="item.erpCode"></el-option>
              </el-select>
              <span v-else>{{ form.supplierCode }}</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="备注说明：">
              <span>{{ form.bz }}</span>
            </el-form-item>
          </div>
        </el-form>
        <div class="header">不良品故障明细附件</div>
        <el-form class="content bascform">
          <div class="col col4">
            <el-form-item label="不良品附件明细">
              <el-button type="primary" size="mini" @click="downloadFile" v-if="form.filePath">下载</el-button>
              <span v-else>无</span>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="action" v-if="form.status == 1">
        <el-button  size="medium" type="primary" @click="showClosureDialog">结案</el-button>
        <el-button  size="medium" type="primary" @click="querySub">确认并通知供应商</el-button>
      </div>
    </div>
    <el-dialog title="提示" v-model="closureDialog.visible" width="30%">
      <el-form label-position="top" :model="closureDialog">
        <el-form-item label="来料+物料描述（物料编号）+缺陷描述" prop="remark">
          <el-input type="textarea" :row="2" placeholder="请填写备注内容" v-model="closureDialog.remark"></el-input>
        </el-form-item>
      </el-form>
      <el-form-item class="item2">
        <el-button size="mini" @click="closureDialog.visible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="closure">确 认</el-button>
      </el-form-item>
    </el-dialog>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { requestForm, request, baseURL } from '@/assets/js/http.js'
import { tableField, tableData, tableFieldll, tableFieldsc } from './js/setting'
export default {
  components: Component.components,
  name: 'ApplyingDetails',
  data () {
    return {
      abnormalDesc: false, // 异常描述
      tableField: tableField,
      tableFieldll: tableFieldll,
      tableFieldsc: tableFieldsc,
      tableData: tableData,
      defectsList: [],
      // dialogShow: false,
      formDisabled: true,
      form: {},
      associatedDocuments: {}, // 关联凭证
      type: 1,
      id: '',
      closureDialog: {
        visible: false,
        remark: ''
      },
      basicData: []
    }
  },
  mounted () {
    this.ApplyingDetails()
    this.getBasicData()
  },

  methods: {
    // 获取供应商数据
    getBasicData () {
      request('/api/supplier/basicData/list?approvalStatus=3', 'get', { pageNum: 1, pageSize: 100000 }).then((Response) => {
        if (Response.code === '200') {
          const data = Response.data.records || []
          data.forEach(item => {
            const erpCode = this.removeFirstZero(item.erpCode)
            console.log(erpCode)
            item.erpCode = erpCode
          })
          this.basicData = data
        } else {
          this.$message.error(Response.msg)
        }
      })
    },
    supplierNameChange (val) {
      if (val) {
        for (const item of this.basicData) {
          if (item.name === val) {
            this.form.supplierCode = item.erpCode
          }
        }
      }
    },
    erpCodeChange (val) {
      if (val) {
        for (const item of this.basicData) {
          if (item.erpCode === val) {
            this.form.supplierName = item.name
          }
        }
      }
    },
    xiugai () {
      request('/api/quality/sap/updateQuality', 'post', this.form).then((res) => {
        if (res.code === '200') {
          // console.log(res)
          this.$message({
            message: '成功',
            type: 'success'
          })
        }
      })
    },
    // 确认提交下一级
    querySubx () {
      const obj = {
        ...this.form // 待确认
      }
      obj.status = 2
      console.log('obj...', obj)
      request('/api/quality/sap/updateQuality', 'post', obj).then((res) => {
        if (res.code === '200') {
          // console.log(res)
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.$router.push('/QualitySynergy/Notice')
        }
      })
    },
    ApplyingDetails () {
      this.id = this.$route.query.id
      this.type = this.$route.query.type
      if (this.type === '1') { // 来料不合格
        requestForm('/api/quality/getById/' + this.id, 'post').then((res) => {
          if (res.code === '200') {
            this.form = res.data
            this.associatedDocuments = res.data.associatedDocuments
            this.defectsList = res.data.defectsList
          }
        })
      } else { // 质量异常
        requestForm('/api/quality/vo/getById/' + this.id, 'post').then((res) => {
          if (res.code === '200') {
            this.form = res.data
          }
        })
      }
    },
    // 点击确认
    querySub () {
      if (!this.form.supplierName || !this.form.supplierCode) {
        this.$message.error('供应商名称、供应商编号不能为空')
        return false
      }
      const obj = {
        ...this.form,
        id: this.id,
        status: 2 // 待我确认(待供应商确认)
      }
      let url = ''
      if (this.type === '1') { // 来料不合格
        url = '/api/quality/update'
      } else { // 质量异常
        url = '/api/quality/updateVo'
      }
      request(url, 'post', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            type: 'success',
            message: '确认成功'
          })
          this.$router.push('/QualitySynergy/Notice')
        }
      })
    },
    showClosureDialog () {
      if (!this.form.supplierName || !this.form.supplierCode) {
        this.$message.error('供应商名称、供应商编号不能为空')
        return false
      }
      this.closureDialog.visible = true
    },
    // 结案
    closure () {
      if (!this.closureDialog.remark.trim()) {
        this.$message.error('请填写备注内容')
        return false
      }
      const obj = {
        ...this.form,
        id: this.id,
        supplierCode: this.form.supplierCode,
        supplierName: this.form.supplierName,
        status: 4, // 已结案
        bz: this.closureDialog.remark
      }
      let url = ''
      if (this.type === '1') { // 来料不合格
        url = '/api/quality/update'
      } else { // 质量异常
        url = '/api/quality/updateVo'
      }
      request(url, 'post', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            type: 'success',
            message: '结案成功'
          })
          this.$router.push('/QualitySynergy/Notice')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleClose () {
      this.dialogShow = false
    },
    // 返回
    back: () => {
      window.history.back()
    },
    // 下载文件
    downloadFile () {
      if (this.form.filePath) {
        window.open(baseURL + '/api/supplier/material/download?filePath=' + encodeURIComponent(this.form.filePath) + '&fileName=' + encodeURIComponent(`不良品附件明细-${this.form.qualityCode}.xls`))
      }
    },
    removeFirstZero (val) {
      let code = val
      while (code && `${code}`.length > 1 && `${code}`.startsWith('0')) {
        code = `${code}`.substring(1)
      }
      return code
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  @import '@/assets/css/elForm.scss';
 .buttondiv{
  text-align: center;
  float: left;
  width: 100%;
}
.zhong{
  float: left;
  width: 45%;
}
</style>
